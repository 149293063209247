import React from 'react'
import { graphql, Link } from 'gatsby'

import Content from '../components/Content'
import Layout from '../components/Layout'
import './Beranda.css'
import Gallery from '../components/Gallery'

// Export Template for use in CMS preview
export const HomePageTemplate = ({
  section1,
  section2,
  section3,
  section4,
  section5,
  section6,
  section7,
  section8,
  section9,
  gallery
}) => (
  <main className="Home">
    <section className="section">
      <div className="beranda">
        <Content source={section1} />
          <Content source={section2} />
          <div className="textkiri">
            <Content source={section3} />
            <Link to="/service/" hreflang="en" aria-label="Philoshop Development Services"><button className="tombol">Let's Build Something Great</button></Link>
          </div>
          <Content source={section4} />
          <div class="textkanan">
            <Content source={section5} />
            <Link to="/service/" hreflang="en" aria-label="Philoshop Writing Services"><button className="tombol">Let's Build Something Great</button></Link>
          </div>
          <Content source={section6} />
          <div class="textkiri">
            <Content source={section7} />
            <Link to="/service/" hreflang="en" aria-label="Philoshop Design services"><button className="tombol">Let's Build Something Great</button></Link>
          </div>
          <Content source={section8} />
          <div class="textkanan">
            <Content source={section9} />
            <Link to="/service/" hreflang="en" aria-label="Philoshop Business Support"><button className="tombol">Let's Build Something Great</button></Link>
          </div>
      </div>
    </section>
    <section>
      <div className="container">
        <h2 className="taCenter">Our Audit Score</h2> <br />
        <Gallery images={gallery} />
        <br />
        <h4 className="taCenter">Compare us at <a href="https://gtmetrix.com/?url=https%3A%2F%2Fphilo.shop" target="_blank" rel="noopener noreferrer">GTMetrix</a></h4>
      </div> <br /> 
      <Link to="/contact/" hreflang="en" aria-label="Philoshop Business Support"><button className="tombol">Contact Us</button></Link> <br /> <br />
    </section>
  </main>
)

// Export Default HomePage for front-end
const HomePage = ({ data: { page } }) => (
  <Layout meta={page.frontmatter.meta || false}>
    <HomePageTemplate {...page} {...page.frontmatter} body={page.html} />
  </Layout>
)

export default HomePage

export const pageQuery = graphql`
  ## Query for HomePage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query HomePage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      ...Gallery
      html
      frontmatter {
        title
        subtitle
        section1
        section2
        section3
        section4
        section5
        section6
        section7
        section8
        section9
      }
    }
  }
`
